<template>
    <section class="section section-lg our-team">
        <div class="container">
            <div class="row justify-content-center text-center mb-lg">
                <div class="col-lg-8">
                    <h2 class="display-3">The amazing Team</h2>
                    <p class="lead text-muted">According to the National Oceanic and Atmospheric Administration,
                        Ted, Scambos, NSIDClead scentist, puts the potentially record maximum.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="px-4">
                        <img v-lazy="'img/theme/team-1-800x800.jpg'"
                             class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                             style="width: 200px;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Ryan Tompson</span>
                                <small class="h6 text-muted">Web Developer</small>
                            </h5>
                            <div class="mt-3">
                                <base-button tag="a" href="#" type="warning" icon="fa fa-twitter" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="warning" icon="fa fa-facebook" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="warning" icon="fa fa-dribbble" rounded
                                             icon-only></base-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="px-4">
                        <img v-lazy="'img/theme/team-2-800x800.jpg'"
                             class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                             style="width: 200px;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Romina Hadid</span>
                                <small class="h6 text-muted">Marketing Strategist</small>
                            </h5>
                            <div class="mt-3">
                                <base-button tag="a" href="#" type="primary" icon="fa fa-twitter" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="primary" icon="fa fa-facebook" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="primary" icon="fa fa-dribbble" rounded
                                             icon-only></base-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="px-4">
                        <img v-lazy="'img/theme/team-3-800x800.jpg'"
                             class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                             style="width: 200px;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Alexander Smith</span>
                                <small class="h6 text-muted">UI/UX Designer</small>
                            </h5>
                            <div class="mt-3">
                                <base-button tag="a" href="#" type="info" icon="fa fa-twitter" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="info" icon="fa fa-facebook" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="info" icon="fa fa-dribbble" rounded
                                             icon-only></base-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="px-4">
                        <img v-lazy="'img/theme/team-4-800x800.jpg'"
                             class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                             style="width: 200px;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">John Doe</span>
                                <small class="h6 text-muted">Founder and CEO</small>
                            </h5>
                            <div class="mt-3">
                                <base-button tag="a" href="#" type="success" icon="fa fa-twitter" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="success" icon="fa fa-facebook" rounded
                                             icon-only></base-button>
                                <base-button tag="a" href="#" type="success" icon="fa fa-dribbble" rounded
                                             icon-only></base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.our-team {

}
</style>
