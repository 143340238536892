<template>
    <div class="position-relative header-landing">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        
            <div class="container col-8 shape-container d-flex">
                    <div class="col order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="/image/tech/img-header.jpg" class="img-center img-fluid floating" style="border-radius: 14px;" />
            </div>
          </div>
                <div class="col px-0">
                    <div class="row">
                        <div class="col mt-5">
                            <h1 class="display-3  text-white">Bairon
                                <span>Consultoria e Assessoria em Tecnologia</span>
                            </h1>
                            <p class="lead  text-white">
                                A Bairon é uma empresa especializada em soluções de desenvolvimento de software, consultoria e assessoria.
                            </p>
                            <div class="btn-wrapper">
                                <base-button tag="a"
                                            href="#formContatUs"
                                            class="mb-3 mb-sm-0"
                                            type="white"
                                            icon="fa fa-id-card">
                                    Contato
                                </base-button>
                                <base-button tag="a"
                                            href="#carouselProjects"
                                            class="mb-3 mb-sm-0"
                                            type="white"
                                            icon="fa fa-briefcase">
                                    Projetos
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-landing {

}
</style>