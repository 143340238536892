<template>
  <section class="section section-lg pt-0">
    <div class="container">
      <card gradient="warning" no-body shadow-size="lg" class="border-0">
        <div class="p-5">
          <div class="row align-items-center">
            <div class="col-lg-8">
              <h3 class="text-white">
                Tire sua ideia do papel, construímos para você!
              </h3>
              <p class="lead text-white mt-3">
                Os projetos passam pelas seguintes etapas cruciais: Entender,
                planejar e executar.
              </p>
            </div>
            <div class="col-lg-3 ml-lg-auto">
              <base-button
                tag="a"
                block
                size="lg"
                href="#formContatUs"
                type="white">Solicitar uma reunião</base-button>
            </div>
          </div>
        </div>
      </card>
    </div>
  </section>
</template>
<script>
export default {
  name: "message-contact-us",
};
</script>
