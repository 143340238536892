<template>
  <div>
    <Header />
    <Product-Type />
    <ScopedApplication />
    <User-Feedback />
    <Automate-Tasks />
    <Own-Control />
    <Message-Contact-Us />
    <!-- <Our-Team /> -->
    <Carousel-Projects />
    <Technologies-We-Work />
    <section class="section section-lg section-shaped overflow-hidden my-0">
      <div class="shape shape-style-3 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Contact  />
    </section>
  </div>
</template>

<script>
import {
  Header,
  ProductType,
  ScopedApplication,
  UserFeedback,
  AutomateTasks,
  OwnControl,
  CarouselProjects,
  MessageContactUs,
  TechnologiesWeWork
} from '@/components/Tech';

import {Contact} from "@/components"

export default {
  name: 'LandingBody',
  components: {
    Header: Header,
    ProductType: ProductType,
    ScopedApplication: ScopedApplication,
    UserFeedback: UserFeedback,
    AutomateTasks: AutomateTasks,
    OwnControl: OwnControl,
    // OurTeam: () => import('./components/OurTeam'),
    CarouselProjects: CarouselProjects,
    MessageContactUs: MessageContactUs,
    TechnologiesWeWork: TechnologiesWeWork,
    Contact
  }
};
</script>
