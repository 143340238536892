<template>
    <section class="section section-lg pt-lg-0 product-type mt--200">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <div class="col-lg-4">
                            <card class="border-0" hover shadow body-classes="py-5">
                                <icon name="ni ni-check-bold" type="primary" rounded class="mb-4">
                                </icon>
                                <h6 class="text-primary text-uppercase">Sites</h6>
                                <p class="description mt-3">
                                    Todo negócio precisa de sites institucionais, gerenciáveis ou hotsites.
                                <div>
                                    <badge type="primary" rounded>clientes</badge>
                                    <badge type="primary" rounded>visibilidade</badge>
                                    <badge type="primary" rounded>explore</badge>
                                </div>
                                <!--<base-button tag="a" href="#" type="primary" class="mt-4">
                                    Learn more
                                </base-button>-->
                            </card>
                        </div>
                        <div class="col-lg-4">
                            <card class="border-0" hover shadow body-classes="py-5">
                                <icon name="ni ni-istanbul" type="success" rounded class="mb-4">
                                </icon>
                                <h6 class="text-success text-uppercase">Aplicativos mobile</h6>
                                    <p class="description mt-3">
                                        Esteja onde os seus usuários estão. Aplicativo mobile para android e iOS.</p>
                                    <div>
                                        <badge type="success" rounded>mobile</badge>
                                        <badge type="success" rounded>android</badge>
                                        <badge type="success" rounded>ios</badge>
                                    </div>
                                    <!--<base-button tag="a" href="#" type="success" class="mt-4">
                                        Learn more
                                    </base-button>-->
                                </card>
                        </div>
                        <div class="col-lg-4">
                            <card class="border-0" hover shadow body-classes="py-5">
                                <icon name="ni ni-planet" type="warning" rounded class="mb-4">
                                </icon>
                                <h6 class="text-warning text-uppercase">API's REST FULL</h6>
                                <p class="description mt-3">Dados criptografados e armazenados com segurança.</p>
                                <div>
                                    <badge type="warning" rounded>segurança</badge>
                                    <badge type="warning" rounded>dados</badge>
                                    <badge type="warning" rounded>integrações</badge>
                                </div>
                                <!--<base-button tag="a" href="#" type="warning" class="mt-4">
                                    Learn more
                                </base-button>-->
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.product-type {
}
</style>
